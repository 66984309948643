import TextAbbreviationControl from 'modules/Abbreviations/components/ToolbarAbbreviation/TextAbbreviationControl';
import { wrapperOnMouseDownHack } from 'modules/draftjs';
import React from 'react';
import { EditorProps, EditorSetters } from 'components/ArtboardAssets/CallToAction/editor';
import { CTAStyles, CTAStylesSetters } from 'components/ArtboardAssets/CallToAction/styles';
import { ToolbarDropdownType } from 'components/EditorToolbar/constants';
import CharStyleControl from 'components/EditorToolbar/controls/CharStyleControl';
import FontFamilyControl from 'components/EditorToolbar/controls/FontFamilyControl';
import ScriptControl from 'components/EditorToolbar/controls/ScriptControl';
import { IconType } from 'components/Icon/constants';
import Subtitle from 'components/Toolbar/BaseToolbar/components/Subtitle';
import { Title, HOVER_HINT } from 'components/Toolbar/constants';
import AssetAlignment from 'components/Toolbar/Controls/AssetAlignment/Group';
import AssetColor from 'components/Toolbar/Controls/AssetColor';
import BackgroundImage from 'components/Toolbar/Controls/BackgroundImage';
import Border from 'components/Toolbar/Controls/Border';
import BorderRadius from 'components/Toolbar/Controls/BorderRadius';
import FillColor from 'components/Toolbar/Controls/FillColor';
import FitToCell from 'components/Toolbar/Controls/FitToCell';
import FontColor from 'components/Toolbar/Controls/FontColor';
import FontSize from 'components/Toolbar/Controls/FontSize';
import Link from 'components/Toolbar/Controls/Link';
import Padding from 'components/Toolbar/Controls/Padding';
import TextHorizontalAlignment from 'components/Toolbar/Controls/TextHorizontalAlignment/Group';
import TextVerticalAlignment from 'components/Toolbar/Controls/TextVerticalAlignment';
import AutoFitContentToggle from 'components/Toolbar/Shared/AutoFitContentToggle';
import ControlGroup from 'components/Toolbar/Shared/ControlGroup';
import HeightWidth from 'components/Toolbar/Shared/HeightWidth';
import {
  AssetAlignmentDirection,
  AssetAlignmentType,
  DefaultCallToActionFontColor,
  MinCellWidth,
  ProjectsConfig,
  ProjectType } from 'const';
import BaseToolbar from 'containers/Toolbar/BaseToolbar';
import { intlGet } from 'utils/intlGet';
import callToActionStyles from './styles.module.scss';

type Props = {
  styles: CTAStyles;
  stylesSetters: CTAStylesSetters;
  projectType: ProjectType;
  cellHeight: number;
  maxCellHeight: number;
  minCellHeight: number;
  toggleCellHeight: (height: number) => number;
  cellWidth: number;
  toggleColumnWidth: (newWidth: number) => number;
  disableCellWidthEditing: boolean;
  link: string;
  toggleLink(link: string): void;
  height: number;
  maxHeight?: number;
  width: number;
  maxWidth: number;
  layoutId: string;
  isIncreasingWidthDisabled: boolean;
  editorState: Draft.EditorState;
  toggleInlineStyle: (style: string) => void;
  toggleScriptStyle: (style: string) => void;
  returnFocusToEditor: () => void;
  toggleAutoFitContent(): void;
  isAutoFitContent: boolean;
  editorProps: EditorProps;
  editorSetters: EditorSetters;
};

const Toolbar: React.FunctionComponent<Props> = (props) => {
  const {
    styles,
    stylesSetters,
    cellHeight,
    cellWidth,
    disableCellWidthEditing,
    editorState,
    height,
    isIncreasingWidthDisabled,
    layoutId,
    link,
    maxCellHeight,
    maxHeight,
    maxWidth,
    minCellHeight,
    projectType,
    toggleInlineStyle,
    toggleCellHeight,
    toggleColumnWidth,
    toggleScriptStyle,
    toggleLink,
    width,
    returnFocusToEditor,
    toggleAutoFitContent,
    isAutoFitContent,
    editorProps,
    editorSetters,
  } = props;
  const { allowBackgroundOpacity, allowBackgroundImage } = ProjectsConfig[projectType];
  const [activeDropdown, setActiveDropdown] = React.useState<ToolbarDropdownType | null>(null);

  const toggleTextHorizontalAlignment = (alignment: AssetAlignmentType): void =>
    stylesSetters.textAlignment(styles.textAlignment.set(AssetAlignmentDirection.HORIZONTAL, alignment));

  const toggleTextVerticalAlignment = (alignment: AssetAlignmentType): void =>
    stylesSetters.textAlignment(styles.textAlignment.set(AssetAlignmentDirection.VERTICAL, alignment));

  const toggleFitToCell = (): void => stylesSetters.fitToCell(!styles.fitToCell);

  const wrapperOnMouseDownHackCallback = (event: React.MouseEvent<HTMLDivElement>, dropDownType: ToolbarDropdownType): void => {
    wrapperOnMouseDownHack(event);
    setActiveDropdown(activeDropdown !== dropDownType ? dropDownType : null);
  };

  const closeDialog = (event: MouseEvent, type, isTarget): void => {
    const isStyleDropdownOpen = activeDropdown === type;

    if (!isTarget && isStyleDropdownOpen) {
      setActiveDropdown(null);
    }
  };

  return (
    <BaseToolbar layoutId={layoutId}>
      <Subtitle title={intlGet('EditorToolbar.Asset', 'Cell')} />
      <ControlGroup>
        <HeightWidth
          height={cellHeight}
          maxHeight={maxCellHeight}
          minHeight={minCellHeight}
          toggleHeight={toggleCellHeight}
          disableCellWidthEditing={disableCellWidthEditing}
          minWidth={MinCellWidth.CTA}
          width={cellWidth}
          toggleColumnWidth={toggleColumnWidth}
          isIncreasingWidthDisabled={isIncreasingWidthDisabled}
          toggleAutoFitContent={toggleAutoFitContent}
          isAutoFitContent={isAutoFitContent}
        />
        <AutoFitContentToggle
          isAutoFitContent={isAutoFitContent}
          isAutoHeight={false}
          toggleAutoFitContent={toggleAutoFitContent}
        />
      </ControlGroup>
      <Subtitle title={intlGet('EditorToolbar.Asset', 'CallToAction')} />
      <ControlGroup>
        <HeightWidth
          height={height}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          minWidth={MinCellWidth.CTA}
          toggleHeight={stylesSetters.height}
          toggleWidth={stylesSetters.width}
          width={width}
        />
      </ControlGroup>
      <ControlGroup>
        <FitToCell
          fitToCell={styles.fitToCell}
          toggleFitToCell={toggleFitToCell}
        />
        <AssetAlignment
          alignment={styles.alignment}
          toggleAlignment={stylesSetters.alignment}
        />
      </ControlGroup>
      <ControlGroup>
        <FontFamilyControl
          editorState={editorState}
          wrapperOnMouseDownHackCallback={wrapperOnMouseDownHackCallback}
          returnFocusToEditor={returnFocusToEditor}
          setActiveDropdown={setActiveDropdown}
          closeDialog={closeDialog}
          activeDropdown={activeDropdown}
          setFontFamily={editorSetters.fontFamily}
          activeFontFamily={editorProps.fontFamily}
        />
      </ControlGroup>
      <ControlGroup>
        <FontSize
          fontSize={editorProps.fontSize}
          toggleFontSize={editorSetters.fontSize}
          returnFocusToEditor={returnFocusToEditor}
        />
      </ControlGroup>
      <ControlGroup>
        <CharStyleControl editorState={editorState} onToggle={toggleInlineStyle} />
        <ScriptControl editorState={editorState} onToggle={toggleScriptStyle} />
        <FontColor
          color={editorProps.fontColor}
          defaultColor={DefaultCallToActionFontColor}
          toggleFontColor={editorSetters.fontColor}
        />
      </ControlGroup>
      <ControlGroup>
        <TextHorizontalAlignment
          alignment={styles.textAlignment.get(AssetAlignmentDirection.HORIZONTAL)}
          toggleAlignment={toggleTextHorizontalAlignment}
        />
        <TextVerticalAlignment
          alignment={styles.textAlignment.get(AssetAlignmentDirection.VERTICAL)}
          toggleAlignment={toggleTextVerticalAlignment}
        />
      </ControlGroup>
      <ControlGroup>
        <Link isDisabled={false} link={link} toggleLink={toggleLink} />
        <TextAbbreviationControl
          selectedText={editorProps.abbreviationTerm}
          abbreviationId={editorProps.abbreviationID}
          onChange={editorSetters.abbreviationID}
          returnFocusToEditor={returnFocusToEditor}
        />
        <Padding
          padding={styles.assetPadding}
          togglePadding={stylesSetters.assetPadding}
          dropdownTitle={Title.PADDING_INTERNAL}
          icon={IconType.PADDING_INTERNAL}
          title={Title.PADDING_INTERNAL}
        />
        <Padding
          padding={styles.padding}
          togglePadding={stylesSetters.padding}
          dropdownTitle={Title.PADDING_EXTERNAL}
        />
        <AssetColor
          activeColor={styles.assetBackgroundColor}
          toggleColor={stylesSetters.assetBackgroundColor}
          opacity={styles.assetBackgroundOpacity}
          toggleOpacity={allowBackgroundOpacity ? stylesSetters.assetBackgroundOpacity : undefined}
          gradient={styles.assetBackgroundGradient}
          toggleGradient={stylesSetters.assetBackgroundGradient}
        />
        <BorderRadius
          borderRadius={styles.assetBorderRadius}
          title={HOVER_HINT.CALL_TO_ACTION}
          toggleBorderRadius={stylesSetters.assetBorderRadius}
        />
      </ControlGroup>
      <Border
        className='ml-3'
        border={styles.border}
        toggleBorder={stylesSetters.border}
      />
      <FillColor
        activeColor={styles.backgroundColor}
        toggleColor={stylesSetters.backgroundColor}
        opacity={styles.backgroundColorOpacity}
        toggleOpacity={allowBackgroundOpacity ? stylesSetters.backgroundColorOpacity : undefined}
        gradient={styles.backgroundGradient}
        toggleGradient={stylesSetters.backgroundGradient}
      />
      {allowBackgroundImage && <BackgroundImage
        backgroundImage={styles.backgroundImage}
        toggleBackgroundImage={stylesSetters.backgroundImage}
      />}
      <BorderRadius
        borderRadius={styles.borderRadius}
        title={HOVER_HINT.CELL}
        dropdownWarningMessage={intlGet('EditorToolbar.Tooltip', 'WarningMessage')}
        toggleBorderRadius={stylesSetters.borderRadius}
        dropdownClassName={callToActionStyles.borderRadiusDropdown}
        iconTooltipClassName={callToActionStyles.iconTooltip}
        dropdownWarningIconClassName={callToActionStyles.lastDropdownWarningIcon}
      />
    </BaseToolbar>
  );
};

export default Toolbar;
